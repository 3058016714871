<template>
    <div class="container">
        <div class="columns is-centered content is-small success" v-if="resetRequestSuccess">
            <h2 class="mb-5">{{ $t('We have e-mailed your password reset link!') }}</h2>
            <div class="">
                <router-link class="btn btn-dark" to="/">{{ $t('Home')}}</router-link>
            </div>
        </div>
        <div class="row justify-content-center" v-else>
            <div class="col-md-8">
                <div class="card">
                    <h2 class="h2 card-header "> {{ $t('Send Password Reset Link') }}</h2>
                    <div class="card-body">
                        <form>
                            <div class="row justify-content-center">
                                <BaseInput class="form-group col-md-6"
                                           v-model.trim="formData.email"
                                           type="text"
                                           placeholder="Email"
                                           autocomplete="username"
                                           :error="errors.emailError"
                                           @enter.stop.prevent="resetRequest"
                                ></BaseInput>
                            </div>
                            <div class="row justify-content-center">
                                <loader v-if="loading"/>
                                <button v-else class="btn btn-dark m-4"
                                        type="submit"
                                        @click.prevent.stop="resetRequest"
                                >
                                    {{ $t('Send Password Reset Link') }}
                                </button>
                            </div>
                        </form>
                        <div class="button-colors">
                            <p> {{ $t('Don`t have an account yet?') }} </p>
                            <router-link class="link" to="/register">{{ $t('Register')}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import BaseInput from "../components/base-components/BaseInput";
    import {mapActions} from "vuex";
    import {ERROR} from "../const";
    import Loader from "../components/Loader";
    import {validateEmail} from "@/utils/helpers";

    export default {
        name: "PasswordResetRequest",
        components: {Loader, BaseInput},
        data() {
            return {
                loading: false,
                additionalParamsToErrormessage: null,
                formData: {
                    email: '',
                },
                errors: {
                    emailError: '',
                },
                resetRequestSuccess: false
            }
        },
        methods: {
            ...mapActions(['DO_PASSWORD_RESET_REQUEST']),
            resetRequest() {
                if (this.loading) return;

                this.clearErrors();
                if (!this.formData.email) {
                    !this.formData.email ? this.errors.emailError = 'Enter your Email' : null;
                    return;
                }
                if (!validateEmail(this.formData.email)) {
                    this.errors.emailError = ERROR.WRONG_EMAIL;
                    return;
                }
                this.loading = true;
                this.DO_PASSWORD_RESET_REQUEST({
                    email: this.formData.email,
                })
                    .then(() => {
                        this.loading = false;
                        this.clearErrors();
                        this.resetRequestSuccess = true;
                    })
                    .catch((data) => {
                        this.loading = false;

                        if (data && data.error && data.message) {
                            data.message === ERROR.ALREADY_LOGGED ? this.errors.emailError = "You are already logged in" : null;
                            data.message === ERROR.USER_NOT_FOUND ? this.errors.emailError = "User not found." : null;
                        }
                    })
            },
            clearErrors() {
                this.errors.emailError = '';
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/css/colors";

    .container {
        margin: 150px auto;
    }

    .link {
        color: $link-accent;
    }
    .entertainment{
        body{
            background-color: black!important;
        }
        .success{
            padding-top: 10rem;
            color: #F5F5F5;
        }
        .container {
            margin: 80px auto 0;
        }
        .card-header{
            color: #F5F5F5;
        }
        .card{
            margin-top: 10rem;
            background-color: #080809;
            .button-colors{
                color: #d0d0d0;
            }
        }
        .card-header{
            background-color: #060607;
        }
        .link {
            color: #FFFFFF;
            border-color: #1d2124;
        }
    }
</style>
